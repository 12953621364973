import { Col, Container, Image, Row } from 'react-bootstrap';
import * as animationData from './career.json';
import Lottie from 'react-lottie';
const HeroSection = () => {
  const defaultOptions = {
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };
  return (
    <div
      className='overflow-hidden relative'
      style={{
        backgroundImage: "url('/assets/img/home-page/box-pattern.png')",
        backgroundRepeat: 'repeat',
        // backgroundPosition: 'center',
        backgroundPosition: 'top 10px',
      }}
    >
      <Container className='relative'>
        <Row className='items-center justify-between pt-20 sm:!pt-36 min-h-[calc(100vh)] md:min-h-[calc(650px-80px)] sm:min-h-[calc(550px-80px)]'>
          <Col lg={7} md={6} sm={6}>
            <h3 className='font-roboto font-bold text-tprimary !text-3xl uppercase mb-[1.5rem] sm:mb-[16px] xs:mb-[12px]'>
              Careers
            </h3>
            <p className='text-lg text-ttertiary leading-[30px] w-[85%] mb-12 lg:w-[100%] md:w-full'>
              We're on a mission to innovate and empower talent. At MCPL, we
              foster creativity and growth. Be part of something amazing!
            </p>
          </Col>

          <Col lg={5} md={6} sm={6} className='flex justify-center'>
            <div className='w-[400px] sm:w-[300px]'>
              <Lottie options={defaultOptions} width='100%' speed={0.5} />
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default HeroSection;
