import { Col, Navbar } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import {
  Header as LithoHeader,
  HeaderNav,
  Menu,
} from '../../Litho/Components/Header/Header';

const Header = ({ props }) => {
  return (
    <div
    // style={props?.style}
    >
      <LithoHeader type='reverse-scroll'>
        <HeaderNav
          fluid='fluid'
          theme='dark'
          bg='bg-tsecondary'
          menu='light'
          expand='lg'
          className='py-[0px] px-[35px] md:px-[15px] md:py-[20px] sm:px-0'
        >
          <Col lg={2} sm={6} xs={'auto'} className='mr-auto ps-0'>
            <Link aria-label='header logo' className='flex items-center' to='/'>
              <Navbar.Brand className='inline-block p-0 m-0'>
                <img
                  className='default-logo'
                  width='120'
                  height='36'
                  loading='lazy'
                  src='/assets/img/mcpl-logo.png'
                  alt='logo'
                />
                <img
                  className='alt-logo'
                  width='120'
                  height='36'
                  loading='lazy'
                  src='/assets/img/mcpl-logo.png'
                  alt='logo'
                />
                <img
                  className='mobile-logo'
                  width='100'
                  height='36'
                  loading='lazy'
                  src='/assets/img/mcpl-logo.png'
                  alt='logo'
                />
              </Navbar.Brand>
            </Link>
          </Col>
          <Navbar.Toggle className='order-last md:ml-[25px] sm:ml-[17px]'>
            <span className='navbar-toggler-line !bg-tprimary !rounded-full'></span>
            <span className='navbar-toggler-line !bg-tprimary !rounded-full'></span>
            <span className='navbar-toggler-line !bg-tprimary !rounded-full'></span>
            <span className='navbar-toggler-line !bg-tprimary !rounded-full'></span>
          </Navbar.Toggle>
          <Navbar.Collapse className='justify-end col-auto col-lg-8'>
            <Menu
              data={[
                { title: 'Home', link: '/' },
                { title: 'Products', link: '/products' },
                {
                  title: 'About Us',
                  link: '/about',
                },
                { title: 'Contact Us', link: '/contact' },
                { title: 'Careers', link: '/careers' },
              ]}
              {...props}
            />
          </Navbar.Collapse>
        </HeaderNav>
      </LithoHeader>
    </div>
  );
};

export default Header;
