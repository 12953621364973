import PageWrapper from '../components/basic/PageWrapper';
import HeroSection from '../components/components/CareersPage/HeroSection';
import InfoSection from '../components/components/CareersPage/InfoSection';
import ProductsPageHelmet from '../components/components/ProductsPage/ProductsPageHelmet';

const CareersPage = () => {
  return (
    <>
      <ProductsPageHelmet />
      <PageWrapper>
        <HeroSection />
        <InfoSection />
      </PageWrapper>
    </>
  );
};

export default CareersPage;
