import { Col, Container, Image, Row } from 'react-bootstrap';
import * as animationData from './resume.json';
import Lottie from 'react-lottie';
import { MdEmail } from 'react-icons/md';
import { FaWhatsapp } from 'react-icons/fa';
import { motion } from 'framer-motion';
const InfoSection = () => {
  const defaultOptions = {
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };
  return (
    <Container className='relative py-[100px] lg:py-[90px] md:py-[75px] sm:py-[50px]'>
      <Row className='items-center justify-between'>
        <Col lg={7} md={6} sm={6}>
          <p className='text-xlg text-tprimary leading-[30px] w-[85%] mb-6 lg:w-[100%] md:w-full'>
            SEND YOUR RESUME AT
          </p>

          {/* Email Section */}
          <motion.div
            initial='hidden'
            animate='visible'
            variants={{
              hidden: { x: -100, opacity: 0 }, // Start off-screen to the left
              visible: { x: 0, opacity: 1 }, // Slide in to the original position
            }}
            transition={{ duration: 0.5, delay: 0.2 }} // Animation duration and delay
            className='flex bg-gray-200 w-[270px] p-3 rounded-lg'
          >
            <div className='flex items-center justify-center bg-red-300 w-[30px] h-[30px] rounded-lg'>
              <MdEmail className='text-white text-2xl' /> {/* Email icon */}
            </div>

            <div className='flex items-center ml-3 text-lg font-medium font-serif text-tprimary'>
              <a href='mailto:hr@mcpl.pk' className='hover:underline'>
                hr@mcpl.pk
              </a>
            </div>
          </motion.div>

          <p className='text-xlg text-tprimary leading-[30px] w-[85%] my-6 lg:w-[100%] md:w-full'>
            OR
          </p>

          {/* WhatsApp Section */}
          <motion.div
            initial='hidden'
            animate='visible'
            variants={{
              hidden: { x: -100, opacity: 0 }, // Start off-screen to the left
              visible: { x: 0, opacity: 1 }, // Slide in to the original position
            }}
            transition={{ duration: 0.5, delay: 0.4 }} // Animation duration and delay
            className='flex bg-gray-200 w-[270px] p-3 rounded-lg mb-12'
          >
            <div className='flex items-center justify-center bg-green-500 w-[30px] h-[30px] rounded-lg'>
              <FaWhatsapp className='text-white text-xl' />{' '}
              {/* WhatsApp icon */}
            </div>
            <div className='flex items-center ml-3 text-md font-medium font-serif text-tprimary'>
              <a
                href='https://wa.me/923334077333'
                target='_blank'
                rel='noopener noreferrer'
                className='hover:underline'
              >
                Contact Us On WhatsApp
              </a>
            </div>
          </motion.div>
        </Col>
        <Col lg={5} md={6} sm={6} className=''>
          <Lottie options={defaultOptions} width={300} speed={0.5} />
        </Col>
      </Row>
    </Container>
  );
};

export default InfoSection;
